<template>
  <div v-if="bannerSrc && !searchInitiated" class="w-full">
    <img :src="bannerSrc" class="w-full max-w-full h-auto">
  </div>
  <bread-crumb-tab
    tag="span"
    :items="breadCrumbItems"
    container-class="mb-7 text-lg font-bold"
    :style-obj="bannerSrc && !searchInitiated ? { 'background-color': computedBanner?.themeColor } : {}"
    spacing="mx-3"
    class="cursor-pointer"
  />

</template>

<script>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'GalleryBanner',

    components: {
        BreadCrumbTab: defineAsyncComponent(() => import('./BreadCrumbTab.vue'))
    },

    props: {
        banner: Object,
    },

    setup (props) {
        const store = useStore();
        const breadCrumbItems = computed(() => {
            const items = store.getters['styleGuideTree/getSelectedNodeStack'] || [];
            return items;
        });

        const searchInitiated = computed(() => store.getters['assetsSearch/getSearchInitiated']);

        const computedBanner = computed(() => {
          let tmp
          if (props.banner) tmp = props.banner[0] || props.banner
          return tmp
         })

        const bannerSrc = computed(() => {
          let tmp = computedBanner.value
          return tmp && tmp.renditions && tmp.renditions.original.mezzaninePreview
        });

        return {
            bannerSrc,
            breadCrumbItems,
            searchInitiated,
            computedBanner
        };
    }
};
</script>
